import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/investments"

import investments from "data/investments"

const Apartments = ({ location }) => {
  const title = "Gliwice"

  return (
    <Layout
      location={location}
      seo={{
        title: "Gliwice - Nowe mieszkania na sprzedaż",
        description: "",
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Flats", url: "/en/flats" }}
      />
      <PageHeader title={title} />
      {investments
        ?.filter(item => item.city === "Gliwice")
        .map((item, index) => (
          <Content
            key={index}
            city={item?.city}
            sub_title="Projects on sale"
            investments={item?.investments}
            office={item?.office}
            rmSubHeader
          />
        ))}
      {/* <Popup type="gliwice" lang="en" /> */}
    </Layout>
  )
}

export default Apartments
